<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <!-- ************************** OVERVIEW ***************************** -->
        <section id="overview">
          <div>
            <i18n
              path="overview.paragraph.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #link2>
                <a @click="goToTab(3)">{{ $t("overview.paragraph.link2") }}</a>
              </template>
            </i18n>
          </div>
        </section>
        <!-- ******************** SUMMARY *********************** -->

        <!-- <div class="bsat__subtitle mb-4">{{ $t("summary.title") }}</div>

        <ul class="bsat__paragraph">
          <li
            v-for="(item, i) in summaryUnorderedList"
            :key="i"
            class="my-1"
            v-text="item"
          ></li>
        </ul> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>
        <!-- ************************* RECOMMENDED RESOURCES ******************************* -->

        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recomendedResources.title") }}
          </div>

          <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div>

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link1>
                    <a href="/new?phase=3&step=2">{{
                      $t("recomendedResources.paragraph.link1")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph2.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link1>
                    <a href="/new?phase=3&step=4">{{
                      $t("recomendedResources.paragraph2.link1")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div class="bsat__paragraph mb-4">
                <strong>{{
                  $t("recomendedResources.paragraph3.title")
                }}</strong>
              </div>
              <ul>
                <li
                  v-for="(item, i) in recomendedResourcesUnorderedList"
                  :key="i"
                >
                  <i18n
                    :path="
                      `recomendedResources.paragraph3.unorderedList[${i}].text`
                    "
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #link>
                      <strong>{{ item.link }}</strong>
                    </template>
                  </i18n>
                  <div v-if="item.to">
                    <v-btn
                      outlined
                      width="150"
                      height="26"
                      class="bsat__btn"
                      color="dark"
                      :to="item.to"
                      >{{ $t("visit") }}</v-btn
                    >
                  </div>
                  <div v-else>
                    <a
                      :href="
                        require('@/assets/files/financial_tools.docx').default
                      "
                      target="_blank"
                      download
                    >
                      <v-btn
                        class="bsat__btn"
                        color="dark"
                        dark
                        depressed
                        width="150"
                        height="26"
                        >{{ $t("download") }}</v-btn
                      >
                    </a>
                  </div>
                </li>
              </ul>
            </li>

            <!-- <li>
              <div class="bsat__paragraph mb-4">
                <strong>
                  {{ $t("recomendedResources.guidelines") }}
                </strong>
              </div>
              <a :href="guidelinesFile" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li> -->
          </ul>
        </section>
        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group. "
        ]
      },
      "task": {
        "text": "{title}: Assign specific mechanisms to the specific planned actions",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Strategy development working group",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": {
        "link2": "Phase 3",
        "text": "Based on Step 4 of {link2}, specific identified policy and financial mechanisms should be assigned to each planned action, or group of actions. Until all specific actions are being covered, and then, test that the adopted measures are  functioning as expected, a critical and deep analysis of potential risks is encouraged. The main goal is to assure a good implementation that will turn in a good development of each priority within the strategy."
      }
    },
    "recomendedResources": {
      "guidelines": "Guidelines to financial instruments design",
      "paragraph": {
        "link1": "Step 2, Phase 3",
        "text": "Determined priority areas and their main goals ({link1})"
      },
      "paragraph2": {
        "link1": "Step 4, Phase 3",
        "text": "Analysis from ({link1})"
      },
      "paragraph3": {
        "title": "Analysis, recommendations, and good examples from: ",
        "unorderedList": [
          {
            "link": "Repository of supporting documents and tools for regional policies in the field of bioeconomy",
            "text": "{link}",
            "to": "/repository-of-regional-policies-to-support-bio-based-models"
          },
          {
            "link": "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy",
            "text": "{link}",
            "to": "/repository-of-financing-instruments-and-tools"
          }
        ]
      },
      "title": "Recommended resources: "
    },
    "summary": {
      "title": "Summary of possible methods",
      "unorderedList": [
        "Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group. "
      ]
    },
    "title": "Step 2: Assign specific policy, financial mechanisms, and resources to the defined specific actions",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";

export default {
  name: "Phase4Step1",
  components: {
    ReadMore
  },
  data() {
    return {
      // TODO: 1.3.4_3
      guidelinesFile: "",
      catalogueLink: "https://www.bio-based-solutions.eu/#/",
      bestPractisesLink: "",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    recomendedResourcesUnorderedList() {
      return Object.values(
        this.$t("recomendedResources.paragraph3.unorderedList")
      );
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
