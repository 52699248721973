<template>
  <div id="more" class="mb-4">
    <div class="bsat__paragraph mt-n2">
      {{ intro }}
    </div>

    <a v-if="!readMore" @click="readMore = !readMore">{{ $t("readMore") }}</a>

    <div v-if="readMore" class="my-8">
      <slot></slot>
    </div>

    <a v-if="readMore" @click="readMore = !readMore">{{ $t("readLess") }}</a>
  </div>
</template>

<i18n>
{
	"en": {
		"readLess": "Read less",
		"readMore": "Read more"
	}
}
</i18n>

<script>
export default {
  props: {
    intro: String
  },
  data() {
    return {
      readMore: false
    };
  }
};
</script>

<style></style>
